.terms-page-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  overflow: auto;
  scrollbar-width: thin;
}

.title {
  color: #333;
  text-align: center;
  margin-top: 12px;
  font-size: 24px;
}

.subtitle {
  color: #333;
  text-align: center;
  font-size: 20px;
}

.article-title {
  color: #333;
  margin: 16px;
  font-size: 22px;
}

.article-text {
  margin-bottom: 12px;
  text-align: left;
}

.article-list {
  list-style: circle;
  text-align: left;
  margin-bottom: 12px;
}

.language-container {
  display: flex;
  justify-content: flex-start;
  margin: 16px 0;
}

.language-container select {
  margin: 0 8px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  /* Hide default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add space for custom arrow */
  padding-right: 24px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="black" stroke-width="2" d="M6 9l6 6 6-6"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position */
  background-size: 16px 16px; /* Adjust the size of the arrow */
}

@media (max-width: 768px) {
  .terms-page-wrapper {
    margin-top: 55px;
  }

  .title {
    font-size: 20px;
  }
  .subtitle {
    font-size: 18px;
  }

  .article-title {
    font-size: 20px;
  }

  .article-text {
    font-size: 14px;
  }

  .article-list,
  .article-list > li {
    font-size: 14px;
  }
}
