@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
div,
input,
textarea,
button {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.custom-button {
  background: #3f51b5;
  color: white;
  z-index: 1;
  border: none;
  padding: 12px;
  font-size: 14px;
  width: 100%;
  align-self: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.custom-button.squared {
  width: auto;
  border-radius: 5px;
}

.custom-button.disabled {
  background: #3f51b561;
  cursor: not-allowed;
}

.custom-button:not(.disabled):hover {
  background: #3f51b5;
  box-shadow: #3f51b561 0px 2px 5px;

  /* transform: scale(1.01); */
}

/* INPUT */
.custom-input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: transparent;
  color: #000;
  outline: none;
  box-shadow: #3f51b561 0px 2px 4px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  transition: all 0.15s ease;
}

.custom-input:focus {
  border: 2px solid #3f51b5;
  box-shadow: #3f51b561 0px 2px 5px;
}

.custom-input-with-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: transparent;
  color: #000;
  outline: none;
  box-shadow: #3f51b561 0px 2px 4px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  transition: all 0.15s ease;
}

.custom-input-with-button-container textarea {
  border: none;
  outline: none;
  width: 100%;
  padding-right: 8px;
  resize: none;

  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-input-with-button-container button {
  border: none;
  outline: none;
  background: #3f51b5;
  color: white;
  z-index: 1;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-input-with-button-container button:hover {
  background: #3f51b5;
  box-shadow: #3f51b561 0px 2px 5px;
}

/* on input focus set the custom-input-with-button-container border to #3f51b5 */
.custom-input-with-button-container:focus-within {
  border: 2px solid #3f51b5;
  box-shadow: #3f51b561 0px 2px 5px;
}

@media (max-width: 768px) {
  .custom-input-with-button-container textarea {
    font-size: 12px;
  }
}
