.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  /* background-color: #fff; */
}

.headerContainer {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #f5f6f7;
  display: flex;
  justify-content: space-between;
}

.headerText {
  font-size: 20px;
  font-weight: 600;
  color: #3f51b5;
}

.chatContainer {
  flex: 1;
  padding: 16px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.chatBubble {
  padding: 0.75rem 1rem;
  border-radius: 12px;
  margin: 12px 0;
  max-width: 45%;
}

@media (max-width: 768px) {
  .chatBubble {
    max-width: 80%;
    margin: 12px 5px;
  }
}

.userBubble {
  align-self: flex-end;
  background-color: #3f51b5;
  margin-left: auto;
  width: fit-content;
}

.botBubble {
  align-self: flex-start;
  background-color: #f5f6f7;
  width: fit-content;
}

.chatText {
  color: #000;
  word-break: break-word;
}

.chat-text-paragraph {
  margin: 0;
  font-size: 14px;
  text-align: left;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #f5f6f7;
  padding: 8px 16px;
  gap: 16px;
  min-height: 60px;
  background-color: #fff;
}

.typingIndicator {
  display: flex;
  align-items: center;
  background-color: #f5f6f7;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 12px;
  margin: 12px 0;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #000;
  border-radius: 50%;
  animation: typing 1.2s infinite;
}

@keyframes typing {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.dot:nth-child(1) {
  animation-delay: 0s;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
}
.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.chat-list-item {
  font-size: 14px;
  text-align: left;
}

.chat-list-item::marker {
  color: #3f51b5;
  font-weight: 600;
}

.chat-paragraph {
  font-size: 14px;
  text-align: left;
  margin-block: 10px;
}
.chat-ordered-list {
  font-size: 14px;
  text-align: left;
  margin-block: 10px;
  padding-left: 20px;
}
.chat-unordered-list {
  font-size: 14px;
  text-align: left;
  margin-block: 10px;
  padding-left: 20px;
}
