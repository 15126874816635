.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000;
  transition: height 0.3s ease, width 0.3s ease;
  border-right: 1px solid #f5f6f7;
  z-index: 10;
  overflow: hidden;
}

.menu-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  padding: 0 16px;
}

.menu-title {
  font-size: 16px;
  font-weight: 500;
  color: #3f51b5;
}

.navbar.open {
  width: 270px;
}

.navbar.closed {
  width: 50px;
}

/*media queries*/
@media (max-width: 768px) {
  .navbar {
    position: absolute;
    border: none;
    border-bottom: 2px solid #f5f6f7;
    transition: height 0.3s ease, width 0.3s ease;
  }

  .nav-menu {
    background-color: white;
    margin: 0;
    transition: height 0.3s ease;
  }

  .navbar.open {
    width: 100%;
    height: 200px; /* Adjusted height for open state */
  }

  .navbar.closed {
    width: 100%;
    height: 50px; /* Adjusted height for closed state */
  }

  .nav-menu.closed {
    height: 0;
    overflow: hidden;
  }

  .nav-menu.open {
    height: 150px; /* Adjusted height for nav menu */
  }

  .nav-menu.closed {
    padding: 0 16px;
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .chat-text-paragraph {
    font-size: 12px !important;
  }
  .nav-footer {
    display: none !important;
  }
}

.menu-icon {
  cursor: pointer;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: height 0.3s ease;
}

ul.nav-menu {
  list-style-type: none;
  padding: 16px;
}

.nav-menu li {
  padding: 10px;
  width: 100%;
  text-align: center;
}

.nav-menu .nav-links {
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  height: 20px;
  transition: all 0.3s ease;
}

.nav-menu .nav-links .nav-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.nav-menu .nav-links .nav-title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  transition: all 0.3s ease;
  opacity: 1;
  visibility: visible;
  white-space: nowrap; /* Prevent word breaking */
}

.navbar.closed .nav-menu .nav-links .nav-title {
  opacity: 0;
  visibility: hidden;
}

.navbar.open .nav-menu .nav-links .nav-title {
  opacity: 1;
  visibility: visible;
}

.nav-footer {
  height: 77px;
  border-top: 1px solid #f5f6f7;
  width: 100%;
}
