.feedback-container {
  background-color: #3f51b5;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.feedback-form-container {
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin: 20px;
}
.feedback-form-title {
  font-size: 24px;
  font-weight: 600;
  color: #3f51b5;
  margin: 5px;
}
.feedback-form-name {
  font-size: 32px;
  font-weight: 700;
  color: #3f51b5;
  margin: 0px;
}
.feedback-form-subtitle {
  font-size: 14px;
  color: #777;
  margin: 5px;
}

.feedback-register-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feedback-form-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.feedback-form-group > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.feedback-form-label {
  text-align: left;
  font-weight: 600;
}
.feedback-required {
  color: red;
  margin-left: 5px;
}
.feedback-success-message {
  background-color: #4caf50;
  padding: 15px;
  color: white;
  border-radius: 5px;
}
.feedback-form-field {
  padding: 15px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  font-family: "Poppins", sans-serif;
}

.feedback-form-field.area {
  font-family: "Poppins", sans-serif;
  min-height: 50px;
  resize: vertical;
  width: auto !important;
}

.feedback-form-field:focus {
  outline: none;
  border-color: #3f51b5;
}
