.page-container {
  background-color: #3f51b5;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.form-container {
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin: 20px;
}
.form-title {
  font-size: 24px;
  font-weight: 600;
  color: #2e294e;
}
.form-name {
  font-size: 32px;
  font-weight: 700;
  color: #3f51b5;
}
.form-subtitle {
  font-size: 14px;
  color: #777;
  margin-bottom: 16px;
}
.waves {
  position: absolute;
  bottom: 0;
}
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.75, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
.register-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group > div {
  display: flex;
  flex-direction: column;
  height: 75px;
  gap: 5px;
}

.form-label {
  text-align: left;
  font-weight: 600;
}
.required {
  color: red;
  margin-left: 5px;
}
.success-message {
  background-color: #4caf50;
  padding: 15px;
  color: white;
  border-radius: 8px;
}
.form-field {
  padding: 15px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.phone-input-container {
  display: flex;
  align-items: center;
}
.phone-input-container .selected-flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  text-align: center;
  background-color: white;
  padding: 0;
  border-radius: 8px 0 0 8px;
}

.phone-input-container .flag-dropdown.open .selected-flag {
  border-radius: 8px 0 0 8px;
  border: 2px solid #3f51b5;
  box-shadow: #3f51b561 0px 2px 5px;
  z-index: 1;
}

.phone-input-container .form-field {
  margin: 10px 0;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  width: 85%;
  height: 47px;
  margin-left: 15%;
  border-radius: 0 8px 8px 0;
  margin-bottom: 15px;
  outline: none;
}

.form-field:focus {
  border: 2px solid #3f51b5;
  box-shadow: #3f51b561 0px 2px 5px;
  border-left: 2px solid #3f51b5;
  z-index: 1;
}
.error-message {
  color: #ff0000;
  font-size: 12px;
  text-align: left;
}
.phone-input-button {
  border-radius: 8px 0 0 8px !important;
}

.error-container {
  display: flex;
  flex-direction: column;
  height: 14px;
}

/*media queries*/
@media (max-width: 768px) {
  .form-title, .feedback-form-title {
    font-size: 16px;
  }
  .form-name {
    font-size: 24px !important;
  }
  .form-subtitle, .form-label, .custom-input, .custom-button, .feedback-form-subtitle, .feedback-form-field{
    font-size: 12px !important;
  }
  .phone-input-container .form-field{
    font-size: 12px !important;
  }
}
