.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height of the viewport */
  width: 100%; /* Full width of the viewport */
  background-color: #f0f0f0; /* Light gray background */
}

.dna-wrapper {
  margin-bottom: 20px; /* Space between loader and text */
}

.loading-text {
  font-size: 1rem;
  color: #333; /* Darker text color for better readability */
  animation: fadeIn 2.5s infinite; /* Cool animation effect */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
