.confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #fafafa;
}

.confirmation-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px;
  gap: 8px;
  max-width: 500px;
  margin-bottom: 16px;
  margin-top: -32px;
}

.confirmation-heading {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: auto;
  gap: 5px;
}

.confirmation-subheading {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  margin: auto;
}

.confirmation-heading span {
  font-weight: 600;
}

.confirmation-form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.inputs-container {
  display: flex;
  margin-bottom: 22px;
  gap: 12px;
}

.confirmation-input-phone-number {
  width: 40px;
  height: 40px;
  font-size: 24px;
  text-align: center;
  border: 2px dashed #c4c4c4;
  border-radius: 4px;
  background-color: transparent;
  color: #3f51b5;
}

.confirmation-input-phone-number:focus {
  border: 2px dashed #3f51b5;
  outline: none;
}
